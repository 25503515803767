.PromtPlaceholder {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  max-height: 100vh;

  padding: 0 2rem;

  &__Thumb {
    margin-bottom: 24px;
  }

  &__Heading {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    color: #3c4852;

    margin-bottom: 8px;
  }

  &__Body {
    // font-family: Open Sauce One;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    text-align: center;

    color: #3c4852;
  }

  &__Action {
    margin-top: 16px;

    width: 100%;
  }
}

.CTA {
  background: #5a5cd4;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 0;

  width: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-align: center;

  /* White */

  color: #ffffff;
}
